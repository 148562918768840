.buttonDock {
  height: 50px;
}

.buttonDock-150 {
  height: 150px;
}

.add-complete-element {
  position: fixed;
  left: 50%;
  bottom: 20px;
  max-width: 360px;
  width: 100%;
  -webkit-transform: translate(calc(-50%));
  transform: translate(calc(-50%));
  display: flex;
  justify-content: space-around;
}

.add-button,
.complete-button {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin: 4px 0;
  cursor: pointer;
}

button.add-button.customer-qr {
  margin: 0;
  padding: 0;
}

button.add-button.customer-qr > svg {
  height: 18px;
  width: 18px;
}

.add-complete-element > .add-button,
.add-complete-element > .complete-button {
  width: 50px;
  height: 50px;
  margin: 0px;
}

.add-button > svg,
.complete-button > svg {
  fill: white;
  width: 14px;
  height: 14px;
}

button:active:enabled.add-button > svg,
button:active:enabled.complete-button > svg {
  fill: var(--color-primary);
}

.add-complete-element > .add-button > svg,
.add-complete-element > .complete-button > svg {
  width: 20px;
  height: 20px;
}

.logout-button {
  width: 100%;
  margin-top: 20px;
}

.floating-buttons {
  position: fixed;
  display: flex;
  flex-direction: column;
  bottom: 0;
  gap: 12px;
  padding: 20px 0 32px 0;
  align-self: center;
  width: 100%;
  align-items: center;
  background: linear-gradient(180deg, #fbf6e4 100%, #fdfcf7 100%);
}

.circled-naked-button {
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  align-self: center;
  border-radius: 32px;
  border: 1px solid #b5bec5;
  width: 100%;
}

.circled-naked-button:active {
  background: #475a66;
}
