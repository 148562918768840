.menu-icon {
  margin-left: 8px;
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.szh-menu__item--hover {
  background-color: rgba(255, 187, 1, 0.4);
}

.szh-menu.szh-menu--state-opening,
.szh-menu.szh-menu--state-open,
.szh-menu.szh-menu--state-closing,
.szh-menu.szh-menu--state-closed {
  min-width: auto;
}
