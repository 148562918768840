section.Searchbar {
  display: flex;
  width: 100%;
  margin-bottom: 30px;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row-reverse;
}

section.Searchbar > label {
  display: block;
  width: 100%;
  margin-bottom: 5px;
}

section.Searchbar > input {
  display: inline;
  flex-basis: 10px;
  flex-grow: 1;
  margin-bottom: unset;
}

section.Searchbar > input::placeholder {
  font-style: italic;
}

.bo-sort,
.bo-filter {
  width: 20px;
  height: 20px;
  margin-left: 12px;
}
