.HomeSection {
  width: 100%;
  text-align: left;
  box-sizing: border-box;
  padding: 20px;
  max-width: 400px;
}

.HomeSection h1 {
  margin-bottom: 24px;
  float: left;
}

.HomeSection header {
  display: flex;
  justify-content: space-between;
}

.add-object {
  float: right;
  height: 18px;
  width: 18px;
  fill: var(--color-primary);
}

.HomeSection .edit {
  margin-top: 20px;
}
