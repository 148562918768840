.tos-pp {
  width: 100%;
  text-align: center;
  font-family: Wix Madefor Display;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -0.03em;
  color: rgba(8, 34, 52, 1);
}

.tos-pp span {
  cursor: pointer;
  text-decoration: underline;
}
