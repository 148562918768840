div.errorWrapper {
  padding: 0 5px 32px 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  margin-bottom: 0;
}

.errorWrapper > label {
  text-transform: unset;
}

div.errorWrapper.centered {
  margin-left: auto;
  margin-right: auto;
}
