.select__value-container {
  align-content: center;
  justify-content: flex-start;
}

.select__input > input {
  margin-bottom: inherit;
}

.select__control {
  border: 1px solid var(--color-secondary) !important;
  border-radius: 5px !important;
  box-shadow: none !important;
}

.select__control--is-focused,
.select__control--menu-is-open {
  border: 1px solid var(--color-primary) !important;
  border-radius: 5px !important;
  box-shadow: none !important;
}

.select__menu {
  border-radius: 5px !important;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15) !important;
}

.select__menu-list {
  background-color: var(--color-background);
}

.select__option--is-focused {
  color: rgba(8, 34, 52, 1) !important;
  background-color: rgba(232, 232, 232, 1) !important;
}

.select__option--is-selected {
  color: rgba(8, 34, 52, 1) !important;
  background-color: rgba(8, 34, 52, 0.2) !important;
}

.select__indicator-separator {
  display: none;
}

.select__indicator > svg > path {
  height: 5px;
  width: 10px;
}

.select__single-value,
.select__menu-notice--no-options {
  color: #000000 !important;
}

.select__menu-notice--no-options {
  font-style: italic;
}

.select__placeholder {
  font-style: italic;
}
