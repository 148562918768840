.forgot-password {
  text-align: left;
}

.small-label a {
  font-family: Wix Madefor Display;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -0.03em;
  text-align: left;
  color: rgba(8, 34, 52, 1);
}
