.navigationMenuContainer {
  z-index: 2;
  width: 100%;
  height: 84px;
  box-sizing: border-box;
  display: flex;
  align-items: safe center;
  background: rgba(255, 255, 255, 1);
  color: black;
  position: fixed;
  bottom: 0;
  max-width: 400px;
  left: 50%;
  transform: translateX(-50%);
  padding: 12px;
  box-shadow: 0px 0px 5px 0px rgba(126, 124, 124, 0.13);
}

.navigationMenuContainer a {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  align-items: safe center;
  font-family: Wix Madefor Text;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -0.03em;
  color: rgba(186, 186, 186, 1);
  text-decoration: none;
}

.navigationMenuContainer a.active {
  color: rgba(8, 34, 52, 1);
}

.navigationMenuContainer .navigationScanButton {
  color: rgba(255, 255, 255, 1);
  background: rgba(8, 34, 52, 1);
  border-radius: 48px;
  height: 68px;
  width: 68px;
  max-width: 68px;
  position: relative;
  top: -34px;
  display: flex;
  justify-content: safe center;
}
