.dialogWrapper {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  background-color: var(--color-overlay);
  z-index: 2;
}

.dialogWrapper.top {
  align-items: flex-start;
}
.dialogWrapper.center {
  align-items: center;
}
.dialogWrapper.bottom {
  align-items: flex-end;
}

.dialogWrapper > .card {
  box-sizing: border-box;
  max-width: 400px;
  flex: 1 1 100%;
  padding: 24px;
  flex-direction: column;
}

.dialogWrapper > .card > .cancel,
.dialogWrapper > .card button {
  cursor: pointer;
}

.dialogWrapper > .card > .cancel {
  text-align: right;
  margin-bottom: 24px;
  align-self: end;
  width: 24px;
  height: 24px;
  fill: var(--color-primary);
}

.dialogWrapper > .card > .title {
  margin-bottom: 16px;
  font-weight: bold;
  text-transform: uppercase;
}

.dialogWrapper > .card > .text {
  margin-bottom: 36px;
}

.dialogWrapper > .card button {
  width: 128px;
  height: 28px;
  padding: 6px;
  background-color: var(--color-primary);
}

.dialogWrapper.confirm > .card button {
  background-color: var(--color-error);
}

.dialogWrapper > .card > * {
  flex-basis: 100%;
}

.dialogWrapper > .card .customer-qr-code {
  margin-bottom: 16px;
}
