.AddOrderHeader {
  padding-top: 12px;
  display: flex;
}

.AddOrderHeader label:first-child,
.AddItemTypeRow div:first-child {
  flex-basis: 40%;
  text-align: left;
}

.AddOrderHeader > label,
.AddItemTypeRow > div {
  flex-basis: 30%;
  text-align: center;
}

.AddItemTypeRow {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid #e4e4e4;
}

.AddItemTypeRow input[type='number'] {
  text-align: right;
}
