article.orderDocument .header,
article.orderDocument .orderRow {
  display: flex;
  justify-content: space-between;
  gap: 5px;
}

article.orderDocument .header label:first-child,
article.orderDocument .orderRow label:first-child {
  flex-basis: 30%;
  text-align: left;
}
article.orderDocument .header label:last-child,
article.orderDocument .orderRow label:last-child {
  text-align: right;
}
article.orderDocument .header label,
article.orderDocument .orderRow label {
  flex-basis: 20%;
  text-align: center;
  overflow: auto;
  overflow-wrap: break-word;
}

article.orderDocument .orderRow {
  display: flex;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid #e4e4e4;
}

article.orderDocument .totals {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 10px;
}

article.orderDocument .totals > div {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  flex-direction: row;
}

article.orderDocument .totals > div > label:first-child {
  flex-basis: 65%;
  text-align: right;
}

article.orderDocument .totals .opacity-60 {
  opacity: 0.6;
}
