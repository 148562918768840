.edit-user h2 {
  margin-bottom: 30px;
}

.edit-user form {
  padding-bottom: 10px;
  border-bottom: 1px solid #e4e4e4;
  margin-bottom: 40px;
}

.edit-user .select {
  margin-bottom: 20px;
}

.edit-user .bottomBtn {
  margin-top: 20px;
}
