.bo-success {
  fill: var(--color-active);
}

.bo-fail {
  fill: var(--color-error);
}

.bo-pending {
  fill: var(--color-primary);
}

.bo-success, .bo-fail, .bo-pending {
  margin-left: 5px;
}