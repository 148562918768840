.Toastify__toast-container,
.Toastify__toast-container .Toastify__toast,
.Toastify__toast .Toastify__toast-body {
  min-height: 15px;
  height: min-content;
}

.Toastify__toast-container .Toastify__toast {
  padding: 12px 16px;
  border-radius: 12px;
  margin-bottom: 6px;
  display: flex;
  align-items: safe center;
}

.Toastify__toast.Toastify__toast--default,
.Toastify__toast.Toastify__toast--warning {
  background: rgba(252, 235, 189, 1);
}

.Toastify__toast.Toastify__toast--info {
  background: rgba(221, 239, 253, 1);
}

.Toastify__toast.Toastify__toast--success {
  background: rgba(212, 238, 185, 1);
}

.Toastify__toast.Toastify__toast--error {
  background: rgba(249, 192, 184, 1);
}

.Toastify__toast .Toastify__toast-body {
  font-family: Wix Madefor Text;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: left;
  margin: 0;
}

.Toastify__toast .Toastify__close-button {
  align-self: center;
  color: rgb(8, 34, 52);
}

.Toastify__toast .Toastify__close-button:active,
.Toastify__toast .Toastify__close-button:hover {
  background: unset;
  opacity: 1;
}

.Toastify__toast .Toastify__progress-bar {
  height: 2px;
}
