.edit.unassign > label {
  text-decoration: unset;
  margin-left: unset;
  color: var(--color-error);
}

.edit.unassign > label > svg {
  fill: var(--color-error);
}

.edit.unassign {
  justify-content: center;
  margin: 0;
}

p.not-exist {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
}

label.yellow-link.check-item {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}
