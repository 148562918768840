* {
  margin: 0;
}

main {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
}

#background {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: -2;
  background: linear-gradient(180deg, #fbf6e4 100%, #fdfcf7 100%);
}

body {
  margin: 0;
}

body,
input,
textarea {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

form label,
.general-label {
  margin-bottom: 5px;
  text-align: left;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.05em;
  font-style: normal;
  font-family: Ubuntu;
  font-size: 10px;
  line-height: 11px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

input:not([type='radio']),
textarea,
.select,
.StripeElement {
  margin-bottom: 10px;
  padding: 9px;
}

hr {
  width: 100%;
  height: 15px;
  border: none;
}

label {
  color: rgba(71, 90, 102, 1);
}

h1,
h2,
h3 {
  font-family: Oxygen;
  font-weight: bold;
  letter-spacing: 0.02em;
  color: rgba(8, 34, 52, 1);
}

h1 {
  margin: 0;
  font-size: 34px;
  line-height: 43px;
  margin-bottom: 15px;
}

h2 {
  font-size: 20px;
  line-height: 25px;
  margin-bottom: 62px;
}

h3 {
  font-size: 16px;
  line-height: 20px;
  margin: 20px 0;
  width: 100%;
}

h4,
h5 {
  font-size: 13px;
  line-height: 15px;
  font-weight: normal;
  color: rgba(8, 34, 52, 1);
}

h4,
input.mb62 {
  margin-bottom: 62px;
}

h5 {
  margin-bottom: 28px;
}

.mb15 {
  margin-bottom: 15px;
}

.bo-logo {
  width: 85px;
  height: 80px;
  margin: 28px;
}

h2.name {
  margin-bottom: 25px;
}

.bo-logo.small {
  width: 37px;
  height: 34px;
  margin: 32px;
  margin-bottom: 38px;
}

button {
  background: var(--color-primary);
  border: none;
  border-radius: 5px;
  filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.15));
  font-family: Ubuntu;
  text-transform: uppercase;
  color: #ffffff;
  height: 41px;
  font-weight: bold;
  flex-shrink: 0;
}

button:active {
  background: #ffffff;
  color: var(--color-primary);
}

button:disabled {
  filter: none;
  background: #e4e4e4;
}

form.profile,
.object-form {
  flex-wrap: wrap;
  box-sizing: border-box;
  column-gap: 12px;
  text-align: left;
}

.object-form {
  flex-direction: column;
}

form.profile {
  flex-direction: row;
}

form.profile > button {
  margin: auto;
}

.object-form h2 {
  margin-bottom: 25px;
}

.full-column,
.search-column {
  flex: 1 0 100%;
}

.half-column {
  flex: 1 0 calc(50% - 6px);
}

.back {
  width: auto;
  font-weight: normal;
  margin-bottom: 25px;
  font-size: 13px;
  line-height: 15px;
  text-transform: none;
  letter-spacing: 0.02em;
  text-decoration: none;
  color: rgba(71, 90, 102, 1);
  display: flex;
  align-items: center;
}

.back:hover {
  text-decoration: underline;
}

.bo-back {
  height: 24px;
  margin-right: 8px;
}

div.location-capacity-space {
  margin-top: 10px;
  font-weight: bold;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
