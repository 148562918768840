.BottomNavbar {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  background: #f9f9fb;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15);
  padding: 14px;
  margin-top: auto;
  color: black;
  position: fixed;
  bottom: 0;
  max-width: 400px;
  left: 50%;
  transform: translateX(-50%);
}

.WithNavbar {
  padding-bottom: 75px !important;
}

.BottomNavbar > a {
  flex-basis: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: inherit;
}

.BottomNavbar > a > svg {
  padding-bottom: 8px;
  fill: black;
}

.BottomNavbar > a.active > svg {
  fill: var(--color-primary);
}

.BottomNavbar > a.active {
  color: var(--color-primary);
}
