:root {
  --color-primary: #ffbb01;
  --color-secondary: #e4e4e4;
  --color-white: #ffffff;
  --color-text: #000000;
  --color-background: #f9f9fb;
  --color-error: #ec4242;
  --color-active: #43be40;
  --color-alert: #f4c54a;
  --color-overlay: rgba(0, 0, 0, 0.5);
  --color-light-overlay: rgba(0, 0, 0, 0.1);
  --color-text-light: #ffffff;
}
