div.map {
  flex: 0 0 50%;
  height: 50vh;
  width: 100vw;
  align-self: center;
}

.OnMap h2 {
  margin-bottom: 24px;
}
