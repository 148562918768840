.ItemTypeRow {
  display: flex;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid #e4e4e4;
}


.label1 {
  font-weight: normal;
  font-size: 13px;
}

.label2 {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 10px;
}

.ItemTypeRow.Header > *:last-child {
  text-align: center;
}

.ItemTypeRow > *:first-child {
  flex-basis: 60%;
}

.ItemTypeRow > *:last-child {
  flex-basis: 40%;
  flex-grow: 1;
  align-items: center;
  display: flex;
  justify-content: center;
}

.ModifyInput {
  height: 100%;
  width: 28px;
  height: 28px;
  margin: 0 5px;
}

.ItemTypeRow > *:last-child > input {
  margin-bottom: 0;
  align-items: center;
  width: 60px;
  text-align: center;
}

.ItemTypeRow.Header {
  border-bottom: unset;
}

.ItemTypeRow.Header > .general-label {
  margin-bottom: 0;
}