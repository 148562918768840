.qr-scanner {
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  display: flex;
  background: black;
  z-index: 2;
}

.qr-scanner > .device-selector {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 200px;
  z-index: 15;
}

.device-selector * {
  line-height: 18px;
  font-size: 16px !important;
}

.qr-scanner > video {
  object-fit: cover;
  width: 100%;
  height: auto;
}

.qr-scanner > button.cancel {
  background-color: unset;
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.qr-scanner > button.cancel > svg {
  width: 50px;
  height: 50px;
  fill: var(--color-primary);
}

.qr-scanner > button.cancel:active > svg {
  width: 50px;
  height: 50px;
  fill: white;
}

.qr-scanner > .qr-scan-border {
  position: fixed;
  border: 3px solid var(--color-primary);
  width: min(66vw, 66vh);
  height: min(66vw, 66vh);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
  margin: auto;
}

.qr-scanner-expanded {
  display: flex;
  justify-content: safe center;
}

.scanner-container {
  position: absolute;
  color: white;
  height: calc(100% - 57px);
  width: 100%;
  z-index: 5;
  max-width: 360px;
  min-width: 360px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
  align-items: safe center;
}

.scanner-container .qr-scan-border {
  border: 1px solid rgb(255, 255, 255, 1);
  border-radius: 8px;
  width: 75%;
  aspect-ratio: 1/1;
  min-height: 250px;
  z-index: 10;
  box-shadow: 0 0 0 1600px rgba(0, 0, 0, 0.5);
}

.qr-scanner-expanded .scanner-heading {
  z-index: 15;
  text-align: left;
  width: 100%;
  padding-top: 20px;
}

.qr-scanner-expanded .scanner-heading .device-selector {
  padding: 0 20px;
}

.qr-scanner-expanded .scanner-heading h1,
.qr-scanner-expanded .scanner-heading label {
  color: #fff;
  padding: 0 20px;
}

.qrScanner .scanner-buttons {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 15px;
  align-items: safe center;
  padding-bottom: 15px;
  z-index: 15;
}

.scanner-buttons .naked-button {
  color: rgba(232, 232, 232, 1);
  padding-left: 20px;
  padding-right: 20px;
}

.scanner-buttons .main-button {
  background: rgba(255, 255, 255, 1);
  color: rgba(8, 34, 52, 1);
  font-family: Wix Madefor Display;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.02em;
}
