.edit.another {
  justify-content: center;
  margin-bottom: 0;
}

.edit.another > label {
  text-decoration: unset;
  margin-left: unset;
  text-transform: unset;
  font-size: 15px;
}

.assign-select-container {
  display: flex;
  align-items: baseline;
  gap: 15px;
}
