section.impact > header {
  align-items: flex-start;
}

.greenrLogo {
  cursor: pointer;
  width: 100px;
}

section.impact .react-tabs {
  font-family: Oxygen;
  min-height: 520px;
}

section.impact .react-tabs__tab-list {
  display: flex;
  justify-content: center;
  height: 38px;
  margin: 0 0 10px;
  padding: 0;
  border-radius: 5px 5px 5px 5px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15);
}

section.impact .react-tabs__tab {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #72738e;
  width: 100%;
}

section.impact .react-tabs__tab:first-child {
  border-radius: 5px 0 0 5px;
}

section.impact .react-tabs__tab:last-child {
  border-radius: 0 5px 5px 0;
}

section.impact .react-tabs__tab--selected {
  background: var(--color-primary);
  border-color: #aaa;
  color: #fff;
}

section.impact .react-tabs__tab-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

section.impact figure {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  position: relative;
}

section.impact figure .next-tier-img {
  filter: brightness(0.2) opacity(0.2);
}

section.impact figure > * {
  width: 50%;
  padding: 0 10px;
}

section.impact figure figcaption {
  text-align: center;
}

section.impact figure figcaption strong {
  color: var(--color-primary);
}

.impact-toggle {
  display: flex;
  justify-content: space-around;
  padding-bottom: 15px;
  border-bottom: 2px solid var(--color-primary);
}

.impact-toggle > .impact-switch {
  box-shadow: 1px 2px 5px rgb(0 0 0 / 15%);
}

.impact-toggle > .impact-switch > div.react-switch-bg > div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
}

.impact-toggle-label {
  color: var(--color-white);
  font-size: 16px;
}
