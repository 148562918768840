.Merchant > a.card > .merchant {
  display: none;
}

.edit {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 25px;
}

.edit > h2 {
  margin-bottom: 0;
  flex-grow: 1;
}

.edit.center {
  justify-content: center;
}

.edit > a,
.edit > label {
  color: var(--color-primary);
  padding: 5px;
  margin: 5px;
  display: flex;
  align-items: center;
  margin-left: auto;
}

.edit.left {
  justify-content: flex-start;
  margin: 0px;
}

.edit.left > a {
  padding: 0px;
  margin-left: 0px;
}

.edit.center > label {
  margin-left: 5px;
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
}

.edit > label {
  text-decoration: underline;
  cursor: pointer;
}

.edit > a > svg,
.edit > label > svg {
  fill: var(--color-primary);
  padding: 5px;
}

.Merchant h5 {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 10px;
}

.business-label {
  margin-bottom: 13px;
}
