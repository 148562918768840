nav {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  margin: 20px 0 30px;
  padding: 0 20px;
  align-items: center;
}

nav > .bo-logo.small {
  margin: 0;
}

nav > label {
  margin-left: auto;
}

nav > .logout {
  width: 16px;
}

nav > .logout,
nav > .bo-settings {
  margin-left: 10px;
}

nav > .logout:hover,
nav > .bo-settings:hover {
  cursor: pointer;
}

nav > img {
  width: 20px;
  height: 20px;
  margin-left: 5px;
}
