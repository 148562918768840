.Header {
  align-items: center;
}

.Header > h1,
.Header > * {
  margin: 0;
  margin-bottom: 25px;
}

.Header > *:last-child {
  margin-left: auto;
}
