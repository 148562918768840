main.screen-container {
  max-width: 360px;
  justify-content: center;
  align-items: flex-start;
  gap: 48px;
  color: rgba(71, 90, 102, 1);
}

.screen-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.screen-header + * {
  height: 100%;
}

.screen-header img {
  margin: 32px 0 16px;
}

.screen-header h1 {
  font-family: Wix Madefor Display;
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.02em;
  margin: 0;
  text-align: left;
}

.screen-header h2 {
  font-family: Wix Madefor Text;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.01em;
  margin: 0;
  text-align: left;
}

.screen-footer-section {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  justify-content: flex-end;
}

.footer-button-section {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  justify-content: flex-end;
  align-items: safe center;
}
