.App {
  display: flex;
  flex-direction: column;
  align-items: safe center;
  text-align: center;
  height: 100%;
  font-size: 13px;
  color: var(--color-text);
  font-family: Ubuntu;
  max-width: 400px;
  min-width: 300px;
  margin: 0 auto;
}

main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 400px;
  width: 100%;
  padding: 20px;
}

main.scanned-item {
  justify-content: unset;
}

main.scanned-item .return-list {
  width: 100%;
  margin: auto;
  text-align: left;
  box-sizing: border-box;
  padding: 20px;
  max-width: 400px;
}

main > .back,
main > .errorWrapper {
  margin: 0 auto 0 0;
  padding-left: 0;
}

main form {
  padding: 0;
}

input,
textarea,
.select,
.StripeElement {
  background: #ffffff;
  border: 1px solid #e4e4e4;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
}

input,
.select,
.StripeElement {
  height: 35px;
}

input[type='file'] {
  height: unset;
}

textarea {
  resize: vertical;
  height: 50px;
}

.select {
  padding: 0;
  border: 0;
}

.select__indicator,
.select__indicators,
.select__value-container,
.select__control {
  min-height: 33px !important;
  height: 100%;
}

.select__control {
  width: 100%;
  margin: 0;
}

input:focus-visible,
textarea:focus-visible {
  border: 1px solid var(--color-primary);
  outline: none;
}

form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  min-width: 254px;
  box-sizing: border-box;
  padding: 20px;
}

.main-form-section {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

p,
div {
  font-style: normal;
  font-weight: normal;
  line-height: 15px;
  color: rgba(8, 34, 52, 1);
}

.form-register-role {
  width: 254px;
}

.radio-lbl {
  padding: 11px;
  column-gap: 8px;
  height: 35px;
  margin-bottom: 16px;
  box-sizing: border-box;
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15);
  width: 100%;
  height: 100%;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.radio-lbl > p {
  font-weight: bold;
}

input[type='radio'] {
  width: 16px;
  height: 16px;
}

/* .StripeElement {
  height: 40px;
  padding: 10px 12px;
  width: 100%;
  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
} */

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.custom-input-container .StripeElement {
  background-color: rgba(252, 252, 252, 1);
  border: none;
  margin-bottom: 0;
  color: rgba(8, 34, 52, 1) !important;
}

.custom-input-container .StripeElement--focus {
  box-shadow: unset;
}

.continueBtn {
  min-width: 254px;
  background-color: var(--color-primary);
}

.continueBtn:disabled {
  background-color: var(--color-secondary);
}

.bottomBtn {
  width: 100%;
  margin-top: 32px;
}

.midBtn {
  width: 100%;
}

.mb62 {
  margin-bottom: 62px;
}

.errorLabel {
  width: 254px;
}

.qr-code {
  margin: 15px auto 0 auto;
  display: flex;
}

.under-button {
  margin-top: 32px;
}

.yellow-link {
  color: var(--color-primary);
  text-decoration: none;
  letter-spacing: 0.05em;
}

.yellow-link:visited {
  color: var(--color-primary);
}

.sign-in {
  text-align: left;
  margin: 2px 0 20px 0;
}

.terms-section {
  width: 100%;
  max-width: 400px;
  box-sizing: border-box;
  padding: 20px;
  text-align: left;
}

.terms-section > h2 {
  margin-bottom: 32px;
}

.terms-section > label {
  text-align: center;
}

.accept-terms {
  margin-bottom: 32px;
}

.show-password {
  margin-bottom: 10px;
}

.show-password > * {
  cursor: pointer;
}

.accept-terms,
.show-password {
  display: flex;
}

.accept-terms > input,
.show-password > input {
  cursor: pointer;
  width: 16px;
  height: 16px;
  margin-bottom: 0;
  margin-right: 8px;
}

.accept-terms > label,
.show-password > label {
  margin-bottom: 0;
  margin-right: 4px;
  font-size: 13px;
  line-height: 15px;
  font-weight: normal;
  text-transform: none;
}

form .password-checklist {
  margin-bottom: 10px;
  margin-left: 10px;
  text-align: left;
}

form .password-check {
  display: flex;
  padding: 4px 12px;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  background: rgba(244, 243, 240, 0.3);
  margin: 0 -12px -12px;
}

form .password-check > label {
  color: rgba(146, 157, 165, 1);
  font-family: 'Wix Madefor Text';
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 2px;
  text-transform: uppercase;
  align-self: flex-start;
}

.password-check .password-checklist {
  margin-right: 10px;
}

.password-check ul.password-checklist li {
  color: rgba(71, 90, 102, 1);
  font-family: 'Wix Madefor Text';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.24px;
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.password-check ul.password-checklist li.invalid {
  color: rgba(241, 118, 118, 1);
}

.linked-h2 {
  text-decoration: underline;
  cursor: pointer;
}

.work-time {
  display: flex;
}

.work-time > .multi-line {
  margin-left: 5px;
}

.multi-line {
  white-space: pre-line;
}

.justify-normal {
  justify-content: normal;
}

.justify-normal > h2 {
  margin-bottom: 0px;
}

.dimensions-input {
  display: flex;
  align-items: baseline;
}

.dimensions-input > input {
  margin-bottom: 0px;
}

.dimensions-input > label {
  margin: 0px 5px 9px 5px;
}

.Searchbar2 {
  display: none !important;
}

.bo-id {
  margin: 0px;
  margin-bottom: 32px;
  display: flex;
  width: 100%;
  gap: 16px;
}

.bo-id > label,
.bo-id > a {
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
}

.location-quantity {
  display: flex;
  justify-content: space-between;
}

.location-quantity > a {
  color: var(--color-primary);
  display: flex;
  margin-right: 5px;
}

.location-quantity > a > svg {
  fill: var(--color-primary);
  padding-right: 5px;
}

.how-to-img {
  max-width: 100%;
  height: auto;
}

h4.home-h4 {
  margin-bottom: 20px;
}

.language-selector-row.home-lang {
  margin-top: 0px;
}

section.help-links {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  gap: 25px;
}

section.help-links.home-links {
  align-items: baseline;
}

section.help-links.home-links > button > svg {
  height: 20px;
  width: 20px;
}

section.help-links-row > * {
  cursor: pointer;
  margin: 10px;
  text-transform: unset;
}

section.help-links-row > *:active {
  border: black;
}

.Header.flex {
  display: flex;
}

.edit.assign {
  margin-bottom: 15px;
}

.how-to-customer {
  float: right;
}

.how-to-merchant {
  margin: 0 10px 10px auto;
  width: fit-content;
}

.how-to-bo {
  padding: 0;
}

.how-to-bo > button.cancel {
  background-color: unset;
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.how-to-bo > button.cancel > svg {
  width: 50px;
  height: 50px;
  fill: var(--color-primary);
}

.how-to-bo > button.cancel:active > svg {
  width: 50px;
  height: 50px;
  fill: white;
}

.check-status {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.check-status label {
  text-transform: unset;
  font-size: 13px;
  font-family: Ubuntu;
  font-weight: 400;
}

.item-count {
  flex: 1;
}

.error-color {
  color: var(--color-error);
}
.error-color.suspend-notice {
  padding-top: 0;
}
.suspend-notice svg {
  width: 40px;
}

.bo-close {
  margin-bottom: 30px;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.item-icon {
  width: 80px;
}

.invoice.yellow-link {
  float: right;
}

.card-info {
  flex-grow: 1;
}

.card .name.remove-name,
.card-info.info-with-icon {
  padding-left: 14px;
}

.card-row {
  display: flex;
}

.card-row.due-date > .quantity {
  max-width: 150px;
  text-align: right;
}

.card-remove {
  display: flex;
  height: 100%;
  align-items: center;
  padding-left: 14px;
  border-left: 1px solid rgba(0, 0, 0, 0.15);
  cursor: pointer;
}

.card-remove * {
  cursor: pointer;
}

.empty-list-msg {
  color: rgb(128, 128, 128);
  font-style: italic;
  margin-top: 20px;
}

.card.padding-0 {
  padding: 0;
}

.card > img,
.banner-image {
  object-fit: cover;
  width: 100%;
  border-radius: 5px 5px 0 0;
}

.card .card-insides {
  padding: 14px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}

.card .icon-container {
  margin: auto;
}

.yellow-link.with-icon {
  display: flex;
  gap: 3px;
}

.barcode-icon {
  width: 16px;
  height: 16px;
}

.bo-external-link {
  fill: var(--color-primary);
  height: 20px;
  margin-left: auto;
  cursor: pointer;
}

.card.accordion-panel {
  box-sizing: border-box;
  margin: -14px auto 20px auto;
  width: -webkit-calc(100% - 30px);
  width: -moz-calc(100% - 30px);
  width: calc(100% - 30px);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.card.accordion-panel .mapped-labels {
  text-align: right;
}

.card.accordion-panel .centered-label {
  text-align: center;
}

.write-off {
  margin-left: auto;
  margin-right: auto;
}

.upload__image-wrapper {
  width: 100%;
}

.upload__image-wrapper button,
.image-item__btn-wrapper button {
  margin-bottom: 20px;
  width: 50px;
}

/* Google Maps */
/* Removes google logo, report problem and terms of use,  */
a[href^="http://maps.google.com/maps"]
{
  display: none !important;
}
a[href^="https://maps.google.com/maps"]
{
  display: none !important;
}

.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
  display: none;
}
.gmnoprint div {
  background: none !important;
}
/* End of google maps */

.map-header {
  align-items: baseline;
}

.map-marker {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  transform: translate(50%, -50%);
  gap: 6px;
  z-index: 5;
}

.map-marker > svg {
  fill: var(--color-primary);
}

.map-marker.user > svg {
  fill: var(--color-secondary);
}

.map-marker > label {
  background: rgb(204, 204, 204, 0.5);
  text-align: center;
  padding: 0 4px;
}

.privacy-policy {
  max-width: 400px;
}

.privacy-policy h1,
.privacy-policy h2,
.privacy-policy h3,
.privacy-policy h4 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.privacy-policy ul,
.privacy-policy p,
.privacy-policy a,
.privacy-policy li > strong {
  margin-bottom: 10px;
  overflow-wrap: anywhere;
}

.privacy-policy .update-date {
  font-style: italic;
}

.privacy-policy * {
  text-align: left;
}

.uppercase {
  text-transform: uppercase;
}

.terms-text h1,
.terms-text h2 {
  text-align: center;
}

.terms-text img {
  display: block;
  margin: auto;
}

.profile-image {
  width: 200px;
}

.Header .total-scanned {
  display: block;
  margin-bottom: 20px;
}

.type-cards {
  margin-bottom: 20px;
  width: 100%;
}

.type-cards .scanned-item-uuid {
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 1px 5px rgb(0 0 0 / 15%);
  margin-bottom: 15px;
  padding: 8px;
}

.type-cards .scanned-item-uuid:last-child {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
}

.type-cards .scanned-item-uuid label {
  font-weight: normal;
  font-size: 12px;
}

label.scan-to-remove {
  display: block;
  width: fit-content;
  margin-top: 10px;
  margin-left: auto;
  text-align: right;
  color: var(--color-primary);
  cursor: pointer;
  margin-bottom: 10px !important;
}

.count-circle {
  border: 2px solid var(--color-active);
  border-radius: 50%;
  margin-right: 20px;
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
}

::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: rgba(8, 34, 52, 0.16);
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

* {
  scrollbar-color: rgba(8, 34, 52, 0.16);

  scrollbar-width: thin;
}

main.mainHome {
  width: 100%;
  max-width: 400px;
  margin: 0px auto;
  display: flex;
  justify-content: flex-start;
  gap: 32px;
}

main.mainHome .homeHeading {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

main.mainHome h1,
main.mainHome label.subtitle {
  text-align: left;
  width: 100%;
}

main.mainHome label.subtitle {
  margin-bottom: 20px;
  font-size: 15px;
}

main.mainHome .h1-small {
  font-size: 28px;
}

main.mainHome .scanBtn {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
}

main.mainHome .newUserLbl {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  width: 100%;
  margin-bottom: 20px;
}

main.mainHome .bottomBtns {
  display: flex;
  flex-grow: inherit;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  margin-top: 15px;
}

main.mainHome .emptyBtn {
  background-color: unset;
  color: unset;
  border: 2px solid rgb(228, 228, 228);
}

main.mainHome .emptyBtn:active {
  background: var(--color-secondary);
}

/* Buttons */

.button-medium {
  font-family: General Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: center;
  text-transform: unset;
  cursor: pointer;
}

.main-button {
  background: rgba(8, 34, 52, 1);
  border: unset;
  color: rgba(255, 255, 255, 1);
  width: 100%;
  max-width: 360px;
  height: 44px;
  padding: 12px 16px;
  border-radius: 32px;
}

.main-button:hover,
.main-button:active {
  background: rgba(71, 90, 102, 1);
  color: rgba(255, 255, 255, 1);
}
.main-button:disabled {
  background: rgba(8, 34, 52, 0.5);
  color: rgba(255, 255, 255, 1);
  opacity: 50%;
}

.naked-button {
  max-width: 320px;
  background: unset;
  border: unset;
  color: rgba(71, 90, 102, 1);
  padding: 12px;
  border-radius: 32px;
}

.naked-button:hover,
.naked-button:active {
  color: rgba(8, 34, 52, 1);
}

.naked-button:disabled {
  color: rgba(71, 90, 102, 0.4);
}

.buttons-forward-back {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: safe center;
  gap: 12px;
}

.mainDock {
  height: 80px;
}

.loginLink {
  cursor: pointer;
  display: flex;
  align-items: safe center;
  justify-content: flex-end;
  gap: 12px;
  font-family: Wix Madefor Text;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -0.03em;
}

.homeUsers {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
}

.home-bo-id {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.home-bo-id > label:first-child {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.02em;
}

.home-bo-id div {
  display: flex;
  gap: 12px;
  align-items: safe center;
}

.home-bo-id div label {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.02em;
}

.show-qr-button {
  cursor: pointer;
  display: flex;
  align-items: safe center;
  font-weight: 700;
  border: 1px solid rgba(181, 190, 197, 1);
  border-radius: 24px;
  padding: 8px;
}

.homeMainButtons {
  display: flex;
  gap: 16px;
  justify-content: space-between;
  width: 100%;
}

.homeMainButtons.userMainButtons {
  margin: 20px 0;
}

.homeMainButtons .homeMainButton {
  border-radius: 12px;
  box-shadow: 0px 1px 40px 14px rgba(81, 76, 59, 0.04);
  display: flex;
  flex: 1 1 0;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  font-family: Wix Madefor Display;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.02em;
  padding: 16px 16px;
  gap: 8px;
  cursor: pointer;
  min-height: 84px;
  white-space: pre-line;
}

.homeMainButton * {
  cursor: pointer;
}

.homeMainButton.borrow {
  background: radial-gradient(106.15% 106.15% at 106.81% 110.49%, rgba(208, 112, 23, 0.1) 0%, rgba(246, 197, 79, 0.1) 100%), linear-gradient(0deg, #fada68, #fada68);
}

.homeMainButton.return {
  background: rgba(254, 253, 250, 1);
}

.homeMainButton label {
  margin: auto 0;
}

label.linkable-span {
  line-height: 20px;
  font-family: Wix Madefor Text;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.02em;
  text-align: center;
}

.linkable-span span {
  cursor: pointer;
  text-decoration: underline;
  color: rgba(8, 34, 52, 1);
  font-family: Bespoke Sans;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.01em;
  text-align: center;
}

.homeMap {
  font-family: Wix Madefor Display;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 12px;
  cursor: pointer;
  width: 100%;
}

.homeImpact {
  width: 100%;
  padding: 20px 0;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 28px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 40px 0px rgba(45, 41, 28, 0.08);
  cursor: pointer;
}

.homeImpact > * {
  padding: 0 20px;
}

.homeImpact img {
  width: 232px;
  height: 256px;
  align-self: center;
}

.homeImpact .impactHeader {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.homeImpact h2 {
  font-family: Wix Madefor Display;
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.03em;
  text-align: left;
}

.homeImpact label {
  font-family: Wix Madefor Text;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.03em;
  text-align: left;
  cursor: pointer;
}

.back-and-langauge-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.back-and-langauge-container a {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  font-family: General Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.01em;
}

.back-and-langauge-container .languageSelectWrapper {
  width: unset;
}

/* TODO change to just a */
a.link {
  font-family: Wix Madefor Text;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -0.03em;
  color: rgba(8, 34, 52, 1);
  text-decoration: none;
}

.small-label {
  font-family: Wix Madefor Display;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -0.03em;
  color: rgba(71, 90, 102, 1);
}

.test-stripe .CardField-child {
  display: block;
  width: 100%;
}

.bopaqs-content {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  width: 100%;
}

.bopaqs-catalogue,
.bopaqs-popular {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.bopaqs-catalogue > label,
.bopaqs-popular > label {
  color: rgba(71, 90, 102, 1);
  font-family: 'Wix Madefor Display';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.32px;
}

.bopaqs-catalogue .bopaqs-catalogue-content {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 1px 40px 14px rgba(81, 76, 59, 0.04), 0px 4px 4px 1px rgba(53, 50, 50, 0.03);
}

.bopaqs-catalogue .bopaqs-catalogue-content > div {
  width: 100%;
}

.bopaqs-catalogue .bopaqs-catalogue-content .item-type-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  padding: 12px 0;
}

.bopaqs-catalogue .bopaqs-catalogue-content .item-type-container .item-type-container-info {
  display: flex;
  align-items: center;
  gap: 16px;
}

.item-type-container .item-type-container-info .item-type-container-info-labels {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.item-type-container .item-type-container-info .item-type-container-info-labels label:first-child {
  color: rgba(8, 34, 52, 1);
  font-family: 'Wix Madefor Display';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.32px;
}

.item-type-container .item-type-container-info .item-type-container-info-labels label:last-child {
  color: rgba(146, 157, 165, 1);
  font-family: 'Wix Madefor Text';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.24px;
}

/* MAP */
.new-map {
  min-width: 360px;
  margin: 0 -20px;
  height: 100%;
  max-height: 100vh;
  z-index: 1;
}

.new-map div:last-child {
  border-radius: 4px;
}

.map-toggle {
  margin-top: 12px;
  z-index: 3;
  position: absolute;
  display: inline-flex;
  gap: 12px;
  align-items: center;
  justify-content: space-around;
  left: 50%;
  transform: translateX(-50%);
}

.map-toggle .map-toggle-container {
  display: flex;
  padding: 4px;
  align-items: flex-start;
  border-radius: 20px;
  background: rgba(252, 252, 252, 1);
  cursor: pointer;
}

.map-toggle .map-toggle-container .map-toggle-btn {
  display: flex;
  width: 112px;
  padding: 4px 0;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: rgba(8, 34, 52, 1);
  font-family: 'Wix Madefor Text';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.28px;
}

.map-toggle .map-toggle-container .map-toggle-btn.active {
  border-radius: 20px;
  background: rgba(255, 222, 89, 1);
  font-weight: 700;
  letter-spacing: -0.14px;
}

.new-map .map-toggle .map-display-list {
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 48px !important;
  background: rgba(252, 252, 252, 1);
  cursor: pointer;
}

.modal-over-everything {
  z-index: 10;
}

.partner-list .modalContent {
  background: unset;
}

.partner-list .modalContainer .modalTitleContainer h1 {
  margin-bottom: 0;
}

.partner-list-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}

.partner-list-container .partner-list-element {
  display: flex;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  padding-bottom: 16px;
  border-bottom: 1px solid #d9d9d9;
}

.partner-list-container .partner-list-element img {
  width: 64px;
  height: 48px;
  object-fit: cover;
}

.partner-list-container .partner-list-element .partner-list-element-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
}

.partner-list-container .partner-list-element .partner-list-element-info label {
  font-family: 'Wix Madefor Text';
  font-style: normal;
  line-height: 20px;
}

.partner-list-container .partner-list-element .partner-list-element-info label:first-child {
  color: rgba(8, 34, 52, 1);
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -0.14px;
}

.partner-list-container .partner-list-element .partner-list-element-info label:last-child {
  color: rgba(71, 90, 102, 1);
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.24px;
}

.partner-list-container .partner-list-element svg {
  display: flex;
  width: 24px;
  padding: 4px 0;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 48px;
  border: 1px solid rgba(8, 34, 52, 0.3);
  cursor: pointer;
}

.scanned-info-container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
}

.scanned-info-container h1 {
  font-family: Wix Madefor Display;
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.02em;
  text-align: left;
  color: rgba(8, 34, 52, 1);
}

.scanned-info-container .scanned-info {
  box-shadow: 0px 1px 40px 14px rgba(81, 76, 59, 0.04), 0px 4px 4px 1px rgba(53, 50, 50, 0.03);
  text-align: left;
  padding: 20px 16px;
  background: rgba(255, 255, 255, 1);
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.scanned-info-container .scanned-info h2 {
  margin-bottom: 24px;
  font-family: Wix Madefor Display;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  color: rgba(8, 34, 52, 1);
}

.scanned-info-property {
  white-space: pre-wrap;
}

.scanned-info-property > label {
  color: rgba(71, 90, 102, 1);
  font-family: 'Wix Madefor Text';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.28px;
}

.scanned-info-property > p {
  font-family: Wix Madefor Text;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: rgba(8, 34, 52, 1);
}

.scanned-info-item {
  display: flex;
  gap: 12px;
  align-items: center;
}

.timer {
  display: flex;
  flex-direction: column;
}

.timer .timer-date {
  font-size: 20px;
  font-weight: 400;
  line-height: 22px;
}

.timer .timer-time {
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
}

.rc-slider.swipe-button.rc-slider-horizontal {
  padding: 0;
  width: calc(100% - 44px);
}

.rc-slider.swipe-button.rc-slider-horizontal,
.rc-slider.swipe-button.rc-slider-horizontal .rc-slider-rail,
.rc-slider.swipe-button.rc-slider-horizontal .rc-slider-track,
.rc-slider.swipe-button.rc-slider-horizontal .rc-slider-step,
.rc-slider.swipe-button.rc-slider-horizontal .rc-slider-handle {
  height: 44px;
}

.rc-slider.swipe-button.rc-slider-horizontal .rc-slider-track {
  background-color: rgba(8, 34, 52, 0.25);
  border-radius: 32px;
}

.rc-slider.swipe-button.rc-slider-horizontal .rc-slider-rail {
  background-color: rgba(232, 232, 232, 1);
  border-radius: 32px;
}

.rc-slider.swipe-button.rc-slider-horizontal .rc-slider-handle {
  width: 44px;
  margin-top: unset;
  opacity: 100%;
  background-color: rgba(8, 34, 52, 1);
  border-radius: 32px;
  border-color: unset;
}

.rc-slider.swipe-button.rc-slider-horizontal .rc-slider-handle.rc-slider-handle-dragging {
  box-shadow: 0 0 0 5px rgba(71, 90, 102, 1);
}

.rc-slider.swipe-button.rc-slider-horizontal .rc-slider-handle:focus-visible {
  box-shadow: unset;
}

.swipe-button-label {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  position: relative;
  top: -50px;
  padding: 6px;
  border-radius: 16px;
}

.borrow-reminder {
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px 40px 14px rgba(81, 76, 59, 0.04), 0px 4px 4px 1px rgba(53, 50, 50, 0.03);
  padding: 20px 16px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: 32px;
  gap: 4px;
}

.borrow-reminder label:first-child {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.borrow-reminder label:last-child {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}
