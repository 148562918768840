.container-info {
  display: flex;
  flex-direction: column;
}

.container-info > div {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.container-info > div > label {
  font-size: 12px;
}

.container-info > div > label:not(:first-child) {
  font-weight: unset;
  text-transform: unset;
}
