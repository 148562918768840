.small-label {
  font-family: Wix Madefor Text;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.02em;
}

.deposit-content {
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.deposit-content p {
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-family: Wix Madefor Text;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: left;
}

.deposit-content p label.deposit-email {
  font-family: Wix Madefor Display;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
  color: rgba(8, 34, 52, 1);
}

.deposit-content p label.reset-email {
  font-family: Wix Madefor Text;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: left;
  text-decoration: underline;
  cursor: pointer;
  color: rgba(8, 34, 52, 1);
  width: fit-content;
}

p.general-paragraph {
  font-family: Wix Madefor Text;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
}

p.general-paragraph a {
  color: rgba(8, 34, 52, 1);
}

p.general-paragraph a:visited {
  color: rgba(8, 34, 52, 1);
}
