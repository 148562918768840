a.card {
  cursor: default;
  text-decoration: none;
  color: black;
}

.card {
  align-items: center;
  background: #fff;
  border-radius: 16px;
  display: flex;
  flex-wrap: wrap;
  margin: 16px 0;
  padding: 20px 16px;
  text-align: left;
  box-shadow: 0px 1px 40px 14px rgba(81, 76, 59, 0.04), 0px 4px 4px 1px rgba(53, 50, 50, 0.03);
}

.card h3.name {
  margin: 0;
  flex: 1 0 10px;
  overflow-wrap: anywhere;
  padding-right: 10px;
}

.card .quantity,
.card .dueDate,
.card .location-capacity,
.card .status {
  margin-left: auto;
  float: right;
  margin-bottom: 0;
}

.card .status {
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.card .status div {
  display: flex;
}

.card .dimensions {
  flex: 1 0 100%;
  display: block;
  text-align: left;
}

.card .status {
  text-transform: uppercase;
  font-size: 10px;
  line-height: 11px;
  font-weight: bold;
  display: flex;
  align-items: flex-end;
  letter-spacing: 0.05em;
}

.status img {
  margin-left: 5px;
}

.card-with-icon {
  flex-wrap: nowrap;
}

.card-with-icon .name {
  min-width: min-content;
}

div.added-cards > div:first-child {
  box-shadow: 0px 1px 15px rgb(0 0 0 / 35%);
}
