.modalWrapper {
  overflow-y: auto;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  justify-content: safe center;
  align-items: safe center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 3;
}

.modalContainer {
  background: rgba(252, 252, 252, 1);
  display: flex;
  flex-direction: column;
  margin: 20px;
  height: min-content;
  max-height: 500px;
  width: calc(100% - 40px);
  max-width: 328px;
  padding: 16px 20px;
  border-radius: 16px;
  gap: 24px;
}

.modalTitleContainer {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: baseline;
}

.modalClose {
  cursor: pointer;
}

.modalContent {
  overflow-y: auto;
  background: rgba(232, 232, 232, 1);
  padding: 12px;
  border-radius: 8px;
}

.modalButton {
  width: 100%;
}

.modalWrapper .modalContainer p,
.modalWrapper .modalContainer label,
.modalWrapper .modalContainer span,
.modalWrapper .modalContainer ul {
  font-family: Wix Madefor Text;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: left;
}

.modalWrapper .modalContainer h1,
.modalWrapper .modalContainer h2,
.modalWrapper .modalContainer h3,
.modalWrapper .modalContainer h4 {
  font-family: Wix Madefor Display;
}

.modalContainer h1 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
}

.modalContainer h2 {
  font-size: 20px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.02em;
}

.modalContainer h3 {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
}

.modalContainer p a {
  cursor: pointer;
  text-decoration: underline;
  color: rgba(8, 34, 52, 1);
  font-family: Bespoke Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.01em;
  text-align: center;
}

.deposit-tutorial .welcome-footer {
  padding-bottom: 0px;
}

.deposit-tutorial .modalContainer {
  overflow-y: auto;
}

.modalWrapper.modal-top {
  align-items: flex-start;
}
