section.notification {
  position: fixed;
  left: 0;
  right: 0;
  padding: 20px;
  box-sizing: border-box;
}

section.notification > .card {
  justify-content: center;
}
