.InfoRow {
  display: flex;
  overflow-wrap: anywhere;
  margin-bottom: 25px;
  justify-content: space-between;
}

.InfoRowLocation {
  flex-direction: column;
  display: flex;
  overflow-wrap: anywhere;
  margin-bottom: 25px;
  justify-content: space-between;
}

.InfoRow > * > *:first-child {
  font-weight: bold;
  font-size: 10px;
  line-height: 11px;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.InfoRowLocation > *:first-child {
  font-weight: bold;
  font-size: 10px;
  line-height: 11px;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.InfoRow label {
  display: block;
}

.Order header {
  margin-top: 20px;
}

.Order header div {
  display: flex;
  align-items: center;
}

.edit-link {
  color: var(--color-primary);
  display: flex;
  gap: 5px;
  align-items: center;
  text-decoration: underline;
  letter-spacing: 0.05em;
  cursor: pointer;
  width: fit-content;
}

.edit-link svg {
  fill: var(--color-primary);
}

.object-form.edit-order .InfoRow div:last-child {
  display: flex;
}
