.welcome-wrapper {
  position: absolute;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: safe center;
  justify-content: safe center;
  max-width: 400px;
  width: 100%;
}

.welcome-start {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background: radial-gradient(107.34% 128.86% at 106.81% 110.49%, rgba(208, 112, 23, 0.1) 0%, rgba(246, 197, 79, 0.1) 100%), radial-gradient(34.96% 209.53% at -7.92% 50%, rgba(248, 248, 249, 0.19) 0%, rgba(238, 151, 22, 0.19) 100%), #fbe8a6;
}

.welcome-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px 32px 20px;
  max-width: 400px;
  width: 100%;
  gap: 32px;
}

.welcome-content.welcome-content-main > label {
  color: rgba(8, 34, 52, 1);
}

.welcome-content svg {
  margin-bottom: 32px;
}

.welcome-content h1 {
  font-family: Wix Madefor Display;
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.02em;
  margin-bottom: 16px;
}

.welcome-content h2 {
  font-family: Wix Madefor Display;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  margin-bottom: 4px;
  width: 80%;
}

.welcome-content h3 {
  font-family: Wix Madefor Text;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.01em;
  margin: 0 0 12px 0;
}

.welcome-content label {
  font-family: Wix Madefor Text;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: center;
}

.welcome-content img {
  max-width: 180px;
  max-height: 180px;
}

.welcome-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: safe center;
  max-width: 360px;
  max-height: 750px;
  height: 100%;
  width: 100%;
  align-items: center;
}

.welcome-wrapper section.language-selector-container {
  margin-top: 32px;
  margin-left: 20px;
  justify-content: normal;
  align-self: start;
}

.welcome-wrapper div.language-selector-row > label.active-lang {
  color: rgba(8, 34, 52, 1);
  font-family: General Sans;
}

.previous-step {
  margin-top: 32px;
  margin-left: 20px;
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  align-self: start;
  gap: 8px;
  cursor: pointer;
}

.previous-step label {
  font-family: Wix Madefor Text;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -0.03em;
  text-align: center;
  color: rgba(8, 34, 52, 1);
  cursor: pointer;
}

.previous-step svg {
  height: 16px;
  width: 16px;
}

.previous-step svg path {
  fill: #475a66;
}

.previous-step label:hover,
.previous-step label:active {
  text-decoration: underline;
}

.welcome-footer {
  display: flex;
  flex-direction: column;
  padding: 12px 0 32px 0;
  gap: 32px;
  width: 100%;
}

.carousel-page-indicators {
  display: flex;
  justify-content: center;
  gap: 8px;
}

.carousel-page-indicators .step {
  background: rgba(159, 159, 159, 1);
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.carousel-page-indicators .currentStep {
  width: 24px;
  height: 8px;
  border-radius: 12px;
  background: rgba(8, 34, 52, 1);
}

.back-with-step {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.back-with-step .step-counter {
  align-self: unset;
}

.first-step-container {
  display: flex;
  flex-direction: column;
  width: calc(100% - 40px);
  justify-content: space-evenly;
  border-radius: 16px;
  padding: 20px 16px;
  gap: 12px;
}

.first-step-container div {
  display: flex;
  flex: 1;
  gap: 12px;
}

.first-step-container .first-step-container-element {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: safe center;
  background: rgba(252, 252, 252, 1);
  border-radius: 16px;
  padding: 16px 12px;
}

.first-step-container .first-step-container-element div div {
  width: 16px;
  min-width: 16px;
  height: 16px;
  padding: 4px;
  border: 1px solid rgba(181, 190, 197, 1);
  border-radius: 16px;
  font-family: Wix Madefor Display;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: rgba(8, 34, 52, 1);
  display: flex;
  align-items: safe center;
  justify-content: safe center;
}

.first-step-container .first-step-container-element img {
  width: 84px;
  height: 84px;
}

.first-step-container .first-step-container-element label {
  font-family: Wix Madefor Display;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: rgba(8, 34, 52, 1);
}

.welcome-content-text div {
  background: rgba(252, 252, 252, 1);
  border-radius: 16px;
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-family: Wix Madefor Text;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: left;
}

.welcome-content-text div .text-note {
  font-size: 12px;
  font-weight: 500;
}

.welcome-content-text ol {
  margin-top: 12px;
  padding-inline-start: 16px;
}
