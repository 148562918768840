div.splash-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-light-overlay);
  width: 100%;
  height: 100%;
}

div.splash-screen.with-footer {
  margin-bottom: 75px;
}

.splash-screen svg {
  -webkit-animation-name: lds-heart;
  -webkit-animation-duration: 2000ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: lds-heart;
  -moz-animation-duration: 2000ms;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: lds-heart;
  -ms-animation-duration: 2000ms;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  animation-name: lds-heart;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  transform-origin: center;
  display: inline-block;
}

@keyframes lds-heart {
  0% {
    transform: scale(0.95);
  }
  5% {
    transform: scale(1.1);
  }
  39% {
    transform: scale(0.85);
  }
  45% {
    transform: scale(1);
  }
  60% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(0.9);
  }
}
