section.language-selector-row {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  cursor: pointer;
}

section.language-selector-row > * {
  margin: 5px;
  text-transform: unset;
  font-weight: unset;
  cursor: pointer;
}

section.language-selector-row > *:active {
  border: black;
}

section.language-selector-row > label.active-lang {
  color: var(--color-primary);
  font-weight: bold;
}

.languageSelectWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-self: flex-start;
  gap: 8px;
}

.welcomeLanguageSelect {
  margin-top: 20px;
  margin-left: 20px;
}

.languageSelect {
  height: 28px;
  padding: 4px 8px;
  border-radius: 12px;
  border: 1px solid rgba(8, 34, 52, 0.22) !important;
  background: unset;
  color: rgba(8, 34, 52, 1);
  cursor: pointer;
}

.languageSelect,
.languageSelect:focus,
.languageSelect:hover,
.languageSelect:active,
.languageSelect:visited,
.languageSelect:active {
  border: 1px solid rgba(8, 34, 52, 0.22) !important;
  box-shadow: none !important;
}
