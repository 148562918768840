.work-in-progress {
  width: 100%;
  height: calc(100% - 75px);
}

.work-in-progress figure {
  display: flex;
  justify-content: center;
  align-content: center;
  height: calc(100% - 75px);
  align-items: center;
}

.work-in-progress figure img {
  width: 200px;
  height: 200px;
}

.form-general {
  height: 100%;
  gap: 36px;
}

.form-general .form-input-section .custom-input-wrapper {
  border: 1px solid rgba(217, 217, 217, 1);
  border-bottom: unset;
  display: flex;
  flex-direction: column;
  position: relative;
  background: rgba(252, 252, 252, 1);
}

.form-general .form-input-section .custom-input-wrapper:first-child {
  border-radius: 8px 8px 0 0;
}

.form-general .form-input-section .custom-input-wrapper:last-child {
  border-bottom: 1px solid rgba(217, 217, 217, 1);
  border-radius: 0 0 8px 8px;
}

.form-general .form-input-section .custom-input-wrapper:only-child {
  border-radius: 8px;
}

.form-general .form-input-section .custom-input-wrapper.input-invalid {
  border: 1px solid rgba(226, 65, 43, 1);
}

.form-general .form-input-section .custom-input-wrapper.input-invalid + div {
  border-top-width: 0;
}

.form-general .form-input-section.form-invalid .custom-input-wrapper {
  border-left: 1px solid rgba(226, 65, 43, 1);
  border-right: 1px solid rgba(226, 65, 43, 1);
}

.form-general .form-input-section.form-invalid .custom-input-wrapper:first-child {
  border-radius: 8px 8px 0 0;
  border-top: 1px solid rgba(226, 65, 43, 1);
}

.form-general .form-input-section.form-invalid .custom-input-wrapper:last-child {
  border-bottom: 1px solid rgba(226, 65, 43, 1);
  border-radius: 0 0 8 px8px;
}

.form-general .form-input-section.form-invalid .custom-input-wrapper:only-child {
  border-radius: 8px;
  border-top: 1px solid rgba(226, 65, 43, 1);
}

.custom-input-wrapper label {
  padding-left: 12px;
  padding-top: 6px;
  text-transform: unset;
  font-family: Wix Madefor Text;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: left;
}

.form-general .form-input-section .custom-input-wrapper.custom-input-disabled {
  background: rgba(252, 252, 252, 0.4);
}

.custom-input-container {
  display: flex;
  height: 44px;
  padding: 0px 12px;
  gap: 8px;
  align-items: safe center;
}

.custom-input-container input {
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  color: rgba(8, 34, 52, 1);
  font-family: Wix Madefor Text;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
}

.custom-input-container input::placeholder {
  color: rgba(186, 186, 186, 1);
  font-family: Wix Madefor Text;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
}

.form-general fieldset.custom-input-wrapper.cc-other-inputs {
  display: flex;
  flex-direction: row;
  padding: 0;
}

.cc-other-inputs fieldset {
  width: 100%;
}

fieldset.custom-input-wrapper.cc-other-inputs > fieldset {
  border: unset;
  display: flex;
  flex-direction: column;
  padding-left: 0;
}

.cc-other-inputs fieldset label {
  padding-left: 22px;
}

fieldset.custom-input-wrapper.cc-other-inputs > fieldset:first-child {
  border-right: 1px solid rgba(217, 217, 217, 1);
}

.show-password-icon {
  cursor: pointer;
  padding: 0 2px;
}

.show-password-icon.checked {
  box-shadow: 0 0 2px 2px rgba(117, 117, 117, 0.1);
  border-radius: 16px;
}

.show-password-icon.checked path {
  fill: rgba(71, 90, 102, 1);
}

/* TODO Test invalid fileds */
.form-general .form-input-section fieldset.custom-input-wrapper.single-validation:has(input:invalid) {
  border-bottom: 1px solid;
  border-color: rgba(226, 65, 43, 1);
}

.form-general .form-input-section fieldset.custom-input-wrapper.single-validation:has(input:invalid) + .custom-input-wrapper {
  border-top: unset;
}

.form-general .form-input-section fieldset.custom-input-wrapper.single-validation:has(input[value='']) {
  border-color: rgba(217, 217, 217, 1);
}

.form-general .form-input-section fieldset div.input-note-container {
  background: linear-gradient(0deg, #f4f3f0, #f4f3f0);
  display: flex;
  padding-left: 10px;
  margin-left: -9.5px;
  margin-right: -10px;
  border-radius: 0 0 8px 8px;
}

.form-general .form-input-section fieldset div.input-note-container label.input-note-label {
  font-family: Wix Madefor Text;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: rgba(71, 90, 102, 1);
}

.form-general .form-input-section fieldset:has(div.input-note-container) {
  padding-bottom: 0;
}

.form-general label.skip-label {
  font-family: Wix Madefor Display;
  text-transform: unset;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.03em;
  text-align: center;
  color: rgba(8, 34, 52, 1);
  cursor: pointer;
  width: fit-content;
}

.deposit-button-section {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  justify-content: flex-end;
  align-items: safe center;
}

.step-counter {
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: conic-gradient(rgba(243, 181, 57, 1) var(--progress), rgba(217, 217, 217, 1) 0deg);
  font-size: 0;
}

.step-counter.solo-step-counter {
  align-self: flex-end;
}

.step-counter::after {
  content: attr(data-progress);
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin: 1px;
  border-radius: 50%;
  background: rgba(251, 246, 228, 1);
  text-align: center;
  font-family: Wix Madefor Text;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.02em;
}

/* TABS */
.custom-tab-header {
  display: flex;
  width: 100%;
  max-width: 320px;
  margin-top: 20px;
  justify-content: space-around;
  cursor: pointer;
  z-index: 10;
}

.custom-tab-header .custom-tab {
  padding: 8px 0;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
}

.custom-tab-header .custom-tab.active {
  border-bottom: 1px solid rgba(8, 34, 52, 1);
}

.custom-tab-scanner-active.custom-tab-header .custom-tab.active {
  border-bottom: 1px solid rgba(255, 255, 255, 1);
}

.custom-tab-header .custom-tab label {
  font-family: Wix Madefor Text;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: left;
  cursor: pointer;
}

.custom-tab-header .custom-tab svg {
  height: 16px;
  width: 16px;
}

.custom-tab-header .custom-tab label {
  color: rgba(146, 157, 165, 1);
}

.custom-tab-header .custom-tab.active label {
  color: rgba(8, 34, 52, 1);
}

.custom-tab-scanner-active.custom-tab-header .custom-tab label {
  color: rgba(146, 157, 165, 1);
}

.custom-tab-scanner-active.custom-tab-header .custom-tab.active label {
  color: rgba(255, 255, 255, 1);
}

.custom-tab-scanner-active.custom-tab-header .custom-tab svg path {
  fill: rgba(146, 157, 165, 1);
}

.custom-tab-scanner-active.custom-tab-header .custom-tab.active svg path {
  fill: rgba(255, 255, 255, 1);
}

.custom-tab-header .custom-tab svg path {
  fill: rgba(146, 157, 165, 1);
}

.custom-tab-header .custom-tab.active svg path {
  fill: rgba(8, 34, 52, 1);
}

.custom-tab-panel {
  visibility: hidden;
  display: flex;
  flex-direction: column;
  width: calc(100% - 80px);
}

.custom-tab-panel.active-tab-panel {
  visibility: visible;
}

.last-scanned-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 32px;
  gap: 16px;
}

.last-scanned-item label,
.deposit-item-list > div > label {
  color: rgba(71, 90, 102, 1);
  font-family: 'Wix Madefor Text';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.28px;
}

.last-scanned-item .last-scanned-info-container {
  display: flex;
  padding: 20px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  border-radius: 24px;
  background: #fff;
  box-shadow: 0px 1px 40px 14px rgba(81, 76, 59, 0.04), 0px 4px 4px 1px rgba(53, 50, 50, 0.03);
}

.last-scanned-item .last-scanned-info-container .last-scanned-item-type-info {
  display: flex;
  align-items: center;
  gap: 12px;
  align-self: stretch;
}

.last-scanned-item .last-scanned-info-container .last-scanned-item-type-info label {
  color: rgba(8, 34, 52, 1);
  flex: 1 0 0;
  font-family: 'Wix Madefor Display';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  text-align: left;
}

.last-scanned-item .last-scanned-info-container .last-scanned-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.last-scanned-item .last-scanned-info-container .last-scanned-info-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  text-align: left;
}

.last-scanned-item .last-scanned-info-container .last-scanned-info-item label:first-child {
  color: rgba(146, 157, 165, 1);
  font-family: 'Wix Madefor Text';
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.last-scanned-item .last-scanned-info-container .last-scanned-info-item label:last-child {
  color: rgba(8, 34, 52, 1);
  font-family: 'Wix Madefor Text';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.28px;
}

.last-scanned-refund-status,
.item-refund-status {
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 32px;
  background: rgba(8, 34, 52, 0.07);
  color: rgba(71, 90, 102, 1);
  text-align: center;
  font-family: 'Wix Madefor Text';
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.last-scanned-refund-status.refundable,
.item-refund-status.refundable {
  background: rgba(212, 238, 185, 1);
}

.last-scanned-refund-status.right-aligned {
  align-self: flex-end;
}

.deposit-item-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  margin-top: 32px;
}

.deposit-item-list.self-borrow-list {
  margin-top: 0px;
}

.deposit-item-list.self-borrow-list > div {
  width: 100%;
}

.deposit-item-list div.deposit-item-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.deposit-item-list label.scan-to-remove {
  color: rgba(241, 118, 118, 1);
  font-family: 'Wix Madefor Text';
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -0.39px;
  margin: 0 !important;
  display: flex;
  gap: 8px;
}

.deposit-item-list .items-categorized {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  justify-content: center;
  /* border-radius: 24px;
  background: #fff;
  box-shadow: 0px 1px 40px 14px rgba(81, 76, 59, 0.04), 0px 4px 4px 1px rgba(53, 50, 50, 0.03); */
}

.deposit-item-list .items-categorized > div {
  width: 100%;
}

.deposit-item-list .categorized-item {
  display: flex;
  align-items: center;
  width: 100%;
  height: 80px;
  cursor: pointer;
}

.deposit-item-list.self-borrow-list .categorized-item {
  cursor: unset;
}

.deposit-item-list .categorized-item .categorized-item-info {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.deposit-item-list.self-borrow-list .categorized-item .categorized-item-info {
  flex: 1;
}

.deposit-item-list .categorized-item .categorized-item-info label {
  cursor: pointer;
}

.categorized-item .categorized-item-info .categorized-item-type-info {
  display: flex;
  align-items: center;
  gap: 16px;
}

.deposit-item-list .categorized-item .categorized-item-info .item-type-count label {
  color: rgba(8, 34, 52, 1);
  font-family: 'Wix Madefor Text';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.28px;
}

.categorized-item .categorized-item-info .categorized-item-type-info label {
  color: rgba(8, 34, 52, 1);
  font-family: 'Wix Madefor Display';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.32px;
}

.item-type-count {
  display: flex;
  padding: 10px 0px;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.custom-divider {
  height: 1px;
  width: 100%;
  background: #d9d9d9;
}

.screen-header .item-type-info {
  display: flex;
  align-items: center;
  gap: 16px;
}

.screen-header .item-type-info label {
  color: rgba(8, 34, 52, 1);
  font-family: 'Wix Madefor Display';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}

.screen-header .item-type-info img {
  margin: 0;
}

.scanned-items-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.scanned-items-container > label {
  color: rgba(71, 90, 102, 1);
  font-family: 'Wix Madefor Text';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.28px;
  text-align: left;
}

.scanned-items-container .scanned-item-label {
  display: flex;
  flex-direction: column;
  text-align: left;
  align-self: stretch;
  gap: 8px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 1px 40px 14px rgba(81, 76, 59, 0.04), 0px 4px 4px 1px rgba(53, 50, 50, 0.03);
  padding: 20px 16px;
}

.scanned-items-container .scanned-item-label .label-with-bin {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  width: 100%;
}

.scanned-items-container .scanned-item-label > label {
  color: rgba(8, 34, 52, 1);
  font-family: 'Wix Madefor Text';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.16px;
}

.scanned-items-container .scanned-item-label div.label-with-bin > label {
  color: rgba(146, 157, 165, 1);
  font-family: 'Wix Madefor Text';
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.scanned-items-container .scanned-item-label div.label-with-bin > svg {
  cursor: pointer;
}

.item-refund-status {
  margin-top: 8px;
  align-self: flex-start;
}

main.max-320 {
  max-width: 320px;
}

.items-overview {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.items-overview .items-overview-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
}

.items-overview .items-overview-header label {
  color: rgba(71, 90, 102, 1);
  font-family: 'Wix Madefor Text';
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.items-overview .items-overview-item-type .items-overview-item-type-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 10px 0;
}

.items-overview .items-overview-item-type .items-overview-item-type-info label:first-child {
  color: rgba(8, 34, 52, 1);
  font-family: 'Wix Madefor Text';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.28px;
}

.items-overview .items-overview-item-type .items-overview-item-type-info label:last-child {
  color: rgba(8, 34, 52, 1);
  font-family: 'Wix Madefor Text';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.14px;
}

.items-overview-totals {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  margin-top: 16px;
}

.items-overview-totals label:first-child {
  color: rgba(8, 34, 52, 1);
  font-family: 'Wix Madefor Text';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.16px;
}

.items-overview-totals label:last-child {
  color: rgba(8, 34, 52, 1);
  font-family: 'Wix Madefor Display';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.32px;
}

.support-code-container,
.info-text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.support-code-container .support-code-header label {
  color: rgba(71, 90, 102, 1);
  font-family: 'Wix Madefor Text';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.14px;
}

.support-code-container .support-code-content,
.info-text-container .info-text-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  background: #fff;
  border-radius: 8px;
  border: 1px solid rgba(217, 217, 217, 1);
}

.info-text-container .info-text-content {
  text-align: left;
  padding: 8px;
}

.support-code-container .support-code-content div:first-child {
  display: flex;
  padding: 10px 12px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.support-code-container .support-code-content div:first-child label {
  color: rgba(8, 34, 52, 1);
  font-family: 'Wix Madefor Display';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}

.support-code-container .support-code-content div:last-child {
  border-radius: 0 0 8px 8px;
  background: rgba(244, 243, 240, 1);
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
}

.support-code-container .support-code-content div:last-child label {
  color: rgba(71, 90, 102, 1);
  font-family: 'Wix Madefor Text';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.24px;
  text-align: left;
}

.screen-header + div.items-overview {
  height: unset;
}

.success-complete {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.register-content {
  display: flex;
  flex-grow: 1;
}

.pick-account-type {
  display: flex;
  width: 320px;
  height: 212px;
  justify-content: center;
  gap: 16px;
}

.account-type-container {
  display: flex;
  padding: 12px 8px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 0;
  border-radius: 10px;
  background: rgba(252, 252, 252, 1);
  box-shadow: 0px 1px 40px 14px rgba(81, 76, 59, 0.04), 0px 4px 4px 1px rgba(53, 50, 50, 0.03);
  cursor: pointer;
}

.account-type-container label {
  color: rgba(8, 34, 52, 1);
  font-family: 'Wix Madefor Display';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.32px;
  cursor: pointer;
}

.custom-input-container.standalone-input {
  width: calc(100% - 20px);
  border: 1px solid rgba(217, 217, 217, 1);
  border-radius: 8px;
  margin-bottom: 24px;
}
